import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { formatEther } from 'ethers';
import logo from './randomreward-removebg.png';
import './App.css';
import SelectableText from './SelectableText';

function App() {
  const [tvl, setTvl] = useState(null);
  const contractAddress = '0x5ec1e43163b303b13fea20a892cba7e5568d8a8f';
  const apiKey = 'ZHU9T2C8ZRG1352T177GX1Q87TN77TJGQ6';

  useEffect(() => {
    async function fetchTvl() {
      try {
        const response = await axios.get(
          `https://api.etherscan.io/api?module=account&action=balance&address=${contractAddress}&tag=latest&apikey=${apiKey}`
        );
        const balanceInWei = response.data.result;
        const balanceInEth = formatEther(balanceInWei);
        setTvl(parseFloat(balanceInEth).toFixed(3)); 
      } catch (error) {
        console.error(error);
      }
    }

    fetchTvl();
  }, [contractAddress, apiKey]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <code className="App-title"><SelectableText text="randomreward.eth" /></code>
        <div className="tvl">
          {tvl !== null ? <p>{tvl} ETH up for grabs</p> : <p>Loading...</p>}
      </div>
      </header>
      <div className="instructions">
        <div className="instruction-box">
          <div className="icon">1</div>
          <div className="text">
            <h2>Send ETH</h2>
            <p>Simply send ETH to <code><SelectableText text="randomreward.eth" /></code> for a chance to receive ETH back.*</p>
          </div>
        </div>
        <div className="instruction-box">
          <div className="icon">2</div>
          <div className="text">
            <h2>Wait 1h</h2>
            <p>Random Reward utilizes Ethereum's prevrandao to determine the winner.**</p>
          </div>
        </div>
        <div className="instruction-box">
          <div className="icon">3</div>
          <div className="text">
            <h2>Get lucky?</h2>
            <p>If you are lucky, the ETH will be delivered directly to your wallet.</p>
          </div>
        </div>
      </div>

      <div className='footnote-container'>
        <small className='secondary'>
          * Depending on your wallet, you may need to set a custom gas limit (100~200k) for the transaction to go through. <br/>
          ** It may take longer if there is not enough ETH to pay out the minimum reward (0.5 ETH)
        </small>
      </div>

      <br/><br/>

      <p className='secondary'>Learn more:</p>
      <span className='secondary'>
        <a className="App-link" href="https://github.com/roinevirta/random-rewarder" target="_blank" rel="noopener noreferrer">
          GitHub
        </a>
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <a className="App-link" href="https://etherscan.io/address/0x5ec1e43163b303b13fea20a892cba7e5568d8a8f#code" target="_blank" rel="noopener noreferrer">
          Etherscan
        </a>
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <a className="App-link" href="https://defillama.com/protocol/random-reward" target="_blank" rel="noopener noreferrer">
          DefiLlama
        </a>
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <a className="App-link" href="https://www.roinevirta.id/posts/randomness-and-time-complexity-in-evm" target="_blank" rel="noopener noreferrer">
          Creation Story
        </a>
      </span>
      <br/>
    </div>
  );
}

export default App;