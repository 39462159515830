import React, { useRef } from 'react';

const SelectableText = ({ text }) => {
  const textRef = useRef(null);

  const handleDoubleClick = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(textRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <span ref={textRef} onDoubleClick={handleDoubleClick}>
      {text}
    </span>
  );
};

export default SelectableText;
